import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, switchMap } from 'rxjs';

//Interfaces
import { KeyCloakUser, Usuario } from 'interfaces/Usuario.interface';

//Services
import { ToastrService } from 'ngx-toastr';
import { KeycloakService } from 'services/keycloak.service';
import { UsuariosService } from 'services/usuarios.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<Usuario | null>;

  //private
  private currentUserSubject: BehaviorSubject<Usuario | null>;
  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private toastr: ToastrService,
    private usuariosService: UsuariosService,
    private keycloak: KeycloakService) {

    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(<string>localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  isTokenNoValid() {
    try {
      return this.keycloak.isTokenExpired();
    } catch (e) {
      return true;
    }
  }


  isTokenNoValidWithToken() {
    return this.keycloak.isTokenExpiredWithToken();
  }

  login() {
    let opciones = { idpHint: 'google' };
    return this.keycloak.createLoginUrl(opciones)
  }

  setUser() {
    console.log('setUser')
    return new Promise((resolve, reject) => {
      this.keycloak.loadUserInfo().then((user:KeyCloakUser) => {
        console.log('user', user);
        this.usuariosService.obtenerPorEmail(user.email)
          .pipe(
            catchError((error:HttpErrorResponse) => {
              switch (error.status) {
                case 403:
                  this.toastr.info('Tu usuario debe ser activado','Contacte con el Administrador');
                  break;
                case 404:
                  this.toastr.info('No se encuentra el usuario','Contacte con el Administrador');
                  break;
                default:
                  this.toastr.info('Ocurrio un error al intentar ingresar','Contacte con el Administrador');
                  break;
              }
              localStorage.removeItem('currentUser');
              this.keycloak.logout();
              return of(null)
            })
          )
        .subscribe((result) => {
          //Asignar token a usuario
          const user = Object.assign({ token: this.keycloak.getToken()}, result?.data)
          const permisos = result?.data.cargo?.permisos?.map((permiso) => permiso.codigo) || [];
          localStorage.setItem("permisos", JSON.stringify(permisos))
          delete user.cargo?.permisos;
          localStorage.setItem("currentUser", JSON.stringify(user))
          this.currentUserSubject.next(user)
          resolve(true);
        })
      });
    })
  }

  tienePermiso(codigoPermiso: string): boolean {
    // console.log("codigoPermisoService", codigoPermiso);
    // console.log("codigoPermisoService todos", codigoPermiso);
    
    // Recuperar permisos desde localStorage
    const permisos = JSON.parse(<string>localStorage.getItem("permisos"));
    //console.log("codigoPermisoService todos", permisos);
    // Verificar si los permisos existen y si incluyen el permiso solicitado
    if (permisos && Array.isArray(permisos)) {
      return permisos.includes(codigoPermiso);
    } else {
      return false;
    }
  }


  getToken() {
    return this.keycloak.getToken();
  }

  refreshToken() {
    return this.keycloak.refreshToken()
  }

  /**
   * User logout
   *
   */
  async logout() {
    try {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      if (await this.keycloak.loadUserInfo()) {
        this.keycloak.logout();
      }
      localStorage.removeItem('permisos');
      // notify
      this.currentUserSubject.next(null);
    } catch (e) {
      this.currentUserSubject.next(null);
    }

  }

}
